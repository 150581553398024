/** @jsx jsx */
import {jsx} from '@emotion/core'
import {Component} from 'react'
import {graphql} from 'gatsby'
import RouterLink from '../components/RouterLink'
import Markdown from 'react-markdown'
import {Container, Page, Intro, button} from '../styles'
import Layout from '../components/Layout'
import VolunteerForm from '../components/VolunteerForm'
import DividerPanda from '../components/DividerPanda'

class VolunteerPage extends Component {
    state = {
        isOpen: false,
    }

    handleClick = () => {
        const state = this.state
        state.isOpen = !state.isOpen
        this.setState({state})

        // fire click event for Google Analytics
        if (typeof window !== 'undefined' && typeof window.ga !== 'undefined') {
            window.ga('send', 'event', {
                eventCategory: 'UI',
                eventAction: 'click',
                eventLabel: 'Expand volunteer application',
            })
        }
    }

    focusInput = () => {}

    render() {
        const {
            frontmatter: {title, intro, buttonLabel},
            rawMarkdownBody,
        } = this.props.data.markdownRemark
        return (
            <Layout>
                <Page>
                    <Container>
                        <Intro>
                            <h1>{title}</h1>
                            <p>{intro}</p>
                        </Intro>
                        <button css={button} onClick={this.handleClick}>
                            {buttonLabel}
                        </button>
                    </Container>
                    <DividerPanda />
                    <Container>
                        <div>
                            <VolunteerForm
                                isOpen={this.state.isOpen}
                                focus={this.state.focusInput}
                            />
                        </div>
                        <Markdown
                            children={rawMarkdownBody}
                            renderers={{link: RouterLink}}
                        />
                    </Container>
                </Page>
            </Layout>
        )
    }
}

export default VolunteerPage

export const query = graphql`
    query {
        markdownRemark(frontmatter: {id: {eq: "volunteer"}}) {
            frontmatter {
                title
                intro
                buttonLabel
            }
            rawMarkdownBody
        }
    }
`

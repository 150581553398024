/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState } from 'react';
import { css } from '@emotion/core';
import { Formik } from 'formik';
import axios from 'axios';
import { object, string, boolean } from 'yup';
import InnerForm from './InnerForm';
import FormMessages from '../FormMessages';

export default function VolunteerForm({ isOpen }) {
    const [submit, setSubmit] = useState(null);

    function handleSubmit(values) {
        if (!values.gotcha) {
            delete values.gotcha;
            axios
                .post(`${process.env.GATSBY_LAMBDA_ENDPOINT}/volunteer`, values)
                .then((response) => {
                    if (response.data.error) {
                        setSubmit('fail');
                        return;
                    }
                    setSubmit('success');
                })
                .catch((e) => {
                    setSubmit('fail');
                    console.error(e);
                });
        }
    }

    function resetForm() {
        setSubmit(null);
    }

    const schema = object().shape({
        name: string().required('required'),
        email: string().email('invalid email address').required('required'),
        pronouns: string().required('required'),
        why: string(),
        commitment_time: boolean().oneOf([true]),
        commitment_ethics: boolean().oneOf([true]),
        angry: string(),
        skills: string(),
        police: string().required(),
        politicians: string().required(),
        prisons: string().required(),
    });

    const formWrapper = css`
        position: relative;
        margin-top: 2rem;
        display: none;
    `;

    const open = css`
        display: block;
    `;

    return (
        <div css={[formWrapper, isOpen && open]}>
            {submit !== null ? (
                <FormMessages status={submit} handleClick={resetForm} />
            ) : (
                <Formik
                    initialValues={{
                        name: '',
                        email: '',
                        pronouns: '',
                        why: '',
                        angry: '',
                        skills: '',
                        police: '',
                        politicians: '',
                        prisons: '',
                        commitment_time: false,
                        commitment_ethics: false,
                        gotcha: '',
                    }}
                    onSubmit={handleSubmit}
                    validationSchema={schema}
                    render={InnerForm}
                />
            )}
        </div>
    );
}

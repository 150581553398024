/** @jsx jsx */
import {jsx} from '@emotion/core'
import {css} from '@emotion/core'
import {
    FormCombinedRow,
    FormGroup,
    formControl,
    checkbox,
    radio,
    button,
} from '../../styles'
import vars from '../../styles/vars'
import {Form, Field} from 'formik'

let focused = false

const ErrorMessage = props => {
    const {touched, errors} = props
    if (touched && errors) {
        return (
            <div
                css={css`
                    color: ${vars.red};
                    font-size: 1.3rem;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                `}
            >
                {errors}
            </div>
        )
    }
    return null
}

export default function VolunteerFormInner({
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    isSubmitting,
}) {
    const getClassNames = (touch, error) => {
        if (touch && error) {
            return 'has-error'
        }
        return ''
    }

    return (
        <Form>
            <FormCombinedRow>
                <FormGroup className="form-group--two">
                    <label htmlFor="volunteerName">Name</label>
                    <input
                        css={[
                            formControl,
                            getClassNames(touched.name, errors.name),
                        ]}
                        autoFocus
                        id="volunteerName"
                        type="text"
                        name="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                        ref={input => {
                            if (input && !focused) {
                                input.focus()
                                if (document.activeElement === input) {
                                    focused = true
                                }
                            }
                        }}
                    />

                    <ErrorMessage touched={touched.name} errors={errors.name} />
                </FormGroup>
                <FormGroup className="form-group--two">
                    <label htmlFor="volunteerEmail">{'Email'}</label>
                    <Field
                        css={[
                            formControl,
                            getClassNames(touched.email, errors.email),
                        ]}
                        id="volunteerEmail"
                        type="email"
                        name="email"
                    />

                    <ErrorMessage
                        touched={touched.email}
                        errors={errors.email}
                    />
                </FormGroup>
            </FormCombinedRow>
            <FormGroup>
                <label htmlFor="volunteerPronouns">{'Gender Pronouns'}</label>
                <input
                    css={[
                        formControl,
                        getClassNames(touched.pronouns, errors.pronouns),
                    ]}
                    placeholder="she/her, they/them, ze/zir, etc"
                    id="volunteerPronouns"
                    type="text"
                    name="pronouns"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.pronouns}
                />
                <ErrorMessage
                    touched={touched.pronouns}
                    errors={errors.pronouns}
                />
            </FormGroup>
            <FormGroup>
                <label htmlFor="volunteerWhy">{'Why Monkeywrench?'}</label>
                <textarea
                    css={[formControl, getClassNames(touched.why, errors.why)]}
                    placeholder="What made you interested in volunteering?"
                    id="volunteerWhy"
                    type="textarea"
                    name="why"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.why}
                />
                <ErrorMessage touched={touched.why} errors={errors.why} />
            </FormGroup>
            <FormGroup>
                <label htmlFor="volunteerAngry">
                    {'What are you mad about?'}
                </label>
                <textarea
                    css={[
                        formControl,
                        getClassNames(touched.angry, errors.angry),
                    ]}
                    placeholder="List off a few things about the current social order that you find especially intolerable."
                    id="volunteerAngry"
                    type="textarea"
                    name="angry"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.angry}
                />
                <ErrorMessage touched={touched.angry} errors={errors.angry} />
            </FormGroup>
            <FormGroup>
                <label htmlFor="volunteerSkills">
                    {'Skills? Talents? Interests?'}
                </label>
                <textarea
                    css={[
                        formControl,
                        getClassNames(touched.skills, errors.skills),
                    ]}
                    placeholder="Anything we can help you practice, learn, or teach?"
                    id="volunteerSkills"
                    type="textarea"
                    name="skills"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.skills}
                />
                <ErrorMessage touched={touched.skills} errors={errors.skills} />
            </FormGroup>
            <FormCombinedRow>
                <fieldset>
                    <legend>{'Police?'}</legend>
                    <FormGroup css={radio}>
                        <input
                            type="radio"
                            name="police"
                            id="copsYes"
                            value="yes"
                            checked={values.police === 'yes'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                        />
                        <label
                            htmlFor="copsYes"
                            className={getClassNames(
                                touched.police,
                                errors.police
                            )}
                        >
                            {'yes.'}
                        </label>
                    </FormGroup>
                    <FormGroup css={radio}>
                        <input
                            type="radio"
                            name="police"
                            id="copsNo"
                            value="no"
                            checked={values.police === 'no'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                        />
                        <label
                            htmlFor="copsNo"
                            className={getClassNames(
                                touched.police,
                                errors.police
                            )}
                        >
                            {'no.'}
                        </label>
                    </FormGroup>
                </fieldset>
                <fieldset>
                    <legend>{'Politicians?'}</legend>
                    <FormGroup css={radio}>
                        <input
                            name="politicians"
                            type="radio"
                            value="yes"
                            checked={values.politicians === 'yes'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id="politiciansYes"
                            required
                        />
                        <label
                            htmlFor="politiciansYes"
                            className={getClassNames(
                                touched.politicians,
                                errors.politicians
                            )}
                        >
                            {'yes.'}
                        </label>
                    </FormGroup>
                    <FormGroup css={radio}>
                        <input
                            name="politicians"
                            type="radio"
                            value="no"
                            checked={values.politicians === 'no'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id="politiciansNo"
                            required
                        />
                        <label
                            htmlFor="politiciansNo"
                            className={getClassNames(
                                touched.politicians,
                                errors.politicians
                            )}
                        >
                            {'no.'}
                        </label>
                    </FormGroup>
                </fieldset>
                <fieldset>
                    <legend>{'Prisons?'}</legend>
                    <FormGroup css={radio}>
                        <input
                            type="radio"
                            name="prisons"
                            id="prisonsYes"
                            value="yes"
                            checked={values.prisons === 'yes'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                        />
                        <label
                            htmlFor="prisonsYes"
                            className={getClassNames(
                                touched.prisons,
                                errors.prisons
                            )}
                        >
                            {'yes.'}
                        </label>
                    </FormGroup>
                    <FormGroup css={radio}>
                        <input
                            type="radio"
                            name="prisons"
                            id="prisonsNo"
                            value="no"
                            checked={values.prisons === 'no'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                        />
                        <label
                            htmlFor="prisonsNo"
                            className={getClassNames(
                                touched.prisons,
                                errors.prisons
                            )}
                        >
                            {'no.'}
                        </label>
                    </FormGroup>
                </fieldset>
            </FormCombinedRow>
            <fieldset>
                <legend>{'Expectations'}</legend>
                <FormGroup css={checkbox}>
                    <input
                        type="checkbox"
                        id="commitmentTime"
                        name="commitment_time"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={values.commitment_time === true}
                        required
                    />
                    <label
                        htmlFor="commitmentTime"
                        className={getClassNames(
                            touched.commitment_time,
                            errors.commitment_time
                        )}
                    >
                        {"I'm able to volunteer a minimum of 4 hours a week"}
                    </label>
                </FormGroup>
                <FormGroup css={checkbox}>
                    <input
                        type="checkbox"
                        id="commitmentEthics"
                        name="commitment_ethics"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={values.commitment_ethics === true}
                        required
                    />
                    <label
                        htmlFor="commitmentEthics"
                        className={getClassNames(
                            touched.commitment_ethics,
                            errors.commitment_ethics
                        )}
                    >
                        {
                            'I understand Monkeywrench Books is a project rooted in active opposition to patriarchy, queer/transphobia, white supremacy, colonialism, and ableism and will conduct myself accordingly.'
                        }
                    </label>
                </FormGroup>
            </fieldset>
            <div css={{display: 'none'}}>
                <label htmlFor="gotcha">
                    {"leave blank if you're a human"}
                </label>
                <input
                    name="gotcha"
                    type="text"
                    onChange={handleChange}
                    value={values.gotcha}
                />
            </div>
            <button css={button} type="submit" disabled={isSubmitting}>
                {'Send!'}
            </button>
        </Form>
    )
}
